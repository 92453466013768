.subheader {
    position: relative;    
    width: 100%;
    top: 0; 
    height: 400px;
    background-color: #f4f4f4;

    &.xl {
        height: 600px;
        .subheader-content {
            padding-top: 200px;
        }
    }

    .subheader-img {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .subheader-content {
        position: relative;
        color: $white-color;
        padding-top: 110px;

        .btn {
            padding: 1rem 5rem;
        }
    }
}

header {
    position: absolute;
    width: 100%;
    z-index: 99;
    height: 100px;
}

.navbar {

    .navbar-nav {
        margin-left: auto;
    }

    a, a:hover {
        color: #ffffff;
    }
    .nav-item {
        margin-left: 0.5rem;
        a {
            position: relative;
            font-size: 1.1rem;
            
            &:active, &:focus {
                background-color: $primary-color;
            }

            &:after,
            &.active:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0.3rem; right: 0.3rem;
                height: 0;
                background-color: $white-color;
                transition: 0.2s;
            }
            &.active:after {
                height: 3px;
            }
            &:hover:after {
                height: 3px;
            }

            &.dropdown-toggle {
                &:after {
                    content: unset;
                    position: unset;
                    background-color: unset;
                }
            }
        }
        &.dropdown {
            &:hover {
                .dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown-menu {
            border-radius: 0;
            .dropdown-item {
                color: $dark-color;

                &.active, &:active, &:focus, &:hover {
                    color: $primary-color;
                    background-color: $white-color;
                }
                &:after {
                    content: unset;
                }
            }
        }
    }
    .navbar-toggler {
        &:focus {
            outline: none;
        }
        .navline {
            display: block;
            height: 4px;
            background-color: #ffffff;
            margin: 5px 0px;
            transition: 0.3s;

            &:nth-child(1) {
                width: 30px;
            }
            &:nth-child(2) {
                width: 20px;
            }
            &:nth-child(3) {
                width: 25px;
            }
        }
        &:hover .navline {
            width: 30px;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    header {
        position: relative;
    }

    .navbar-expand-md {
        background-color: $dark-color;
        
        .navbar-toggler {
            padding-right: 0;
        }

        .navbar-nav {
            margin-top: 1rem;
        }

        .nav-item {
            margin-left: 0;
            text-align: center;
            .nav-link {
                padding: 1rem;
                &.active {
                    color: $primary-color;
                }
                &:after,
                &:hover {
                    content: unset !important;
                }
            }
        }
    } 
}
