@import '_variables';
@import '_header';
@import '_card';
@import '_album';
@import '_events';
@import '_news';
@import '_sponsors';
@import '_contact';
@import '_form';
@import '_footer';

body {
    font-family: $text-font;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $header-font;
    letter-spacing: 1px;
}

h1 {
    font-size: 3.5rem;
}

p, span, button, input, a {
    font-family: $text-font;
    line-height: 1.7rem;
    letter-spacing: 0.01rem;
}
input {
    outline-color: $primary-color;
}

a:hover {
    color: $dark-color;
    -o-transition: $transition-time;
    -ms-transition: $transition-time;
    -moz-transition: $transition-time;
    -webkit-transition: $transition-time;
    transition: $transition-time;
}

h2 {
    font-size: 3rem;

    &.normal-heading {
        margin-top: 0.5em;
    }
}

p.lead {
    color: $primary-color;
}

a {
    color: $primary-color;
}

.btn {
    border-radius: 0;
    font-size: 1.2rem;
    letter-spacing: 0.03rem;

    &.btn-primary {
        background-color: $primary-color;
        border: $primary-color;
        color: $white-color;

        &.active {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
        }
    }
}

.text-button a {
    display: inline-block;
    margin: 0.5rem; 
    padding: 0.5rem 2rem;
    border-radius: 0;
    font-size: 1.2rem;
    letter-spacing: 0.03rem;
    background-color: $primary-color;
    border: $primary-color;
    color: $white-color;

    &:first-letter {
        text-transform: uppercase;
    }
    &:hover {
        text-decoration: none;
    }
}

.spacing {
    padding: 0 5rem 0 5rem;
}

.parralax {
    height: 350px;
    width: 100vw;
    margin-left: calc(-50vw + 50% - 8px);
    margin-right: calc(-50vw + 50% - 8px);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.block {     
    position: relative;
    background-color: $white-color;
    padding: 3rem;
    z-index: 1;

    .block-content {
        padding: 3rem;
        background-color: $white-color;
        -webkit-box-shadow: 0px 0px 50px 5px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 0px 50px 5px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 50px 5px rgba(0,0,0,0.1);

        a {
            text-decoration: underline;
        }
    }

    &:after {
        content: '';
        position: absolute;
        left: 0; right:0;
        background-color: $primary-color;
        z-index: -1;
    }
    &.bg-bottom:after {
        top: 50%; bottom: 0;
    }
    &.bg-top:after {
        top: 0; bottom: 50%;
    }
}

ul.events {
    list-style: none;
    margin: 0;
    padding: 0;

    span {
        display: block;
        &.title {
            font-family: $header-font;
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }
        &.date {
            padding-left: 2rem;
            color: $primary-color;
            margin-bottom: 0.8rem;
        }
    }

    li {
        border-bottom: 1px solid rgba($color: $primary-color, $alpha: 0.3);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        margin-bottom: 10px;
    }
}

.heading-special {
    position: relative;
    text-align: center;
    padding-bottom: 0.8rem;
    margin-bottom: 3rem;

    &:after {
        content: '';
        position: absolute;
        left: 35%; right: 35%;
        bottom: 0;
        height: 6px;
        border-top: 2px solid $primary-color;
        border-bottom: 2px solid $primary-color;
    }
}

.responsive-img {
    width: 100%;
    height: auto;
}

@media (max-width: 992px) {
    .heading-special {
        &:after {
            left: 30%; right: 30%;
        }
    }
}

@media (max-width: 768px) {
    .heading-special {
        &:after {
            left: 25%; right: 25%;
        }
    }
}

@media (max-width: 576px) {
    h2 {
        font-size: 2.5rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    p {
        line-height: 2rem;
    }

    .heading-special {
        word-wrap: break-word;
        &:after {
            left: 20%; right: 20%;
        }
    }

    .text-right {
        text-align: left !important;
    }
}

.row {
    margin-bottom: 1rem;
    [class*='col-']:nth-child(4n+1) { 
        clear: left;
    }
}

.table-module {
    max-width: 600px;

    td, th {
        padding: 0.5rem;
        border-top: none;
    }
    thead th {
        border-color: $primary-color;
        width: 75%;
    }
}

input#dhsecrethoneypot {
    display: none;
}
