@include n-columns(290px, 20px, true, 3);

.newsfeed {
    width: 100%;
    height: auto;
    background-color: #eaeaea;
    border-bottom: 1px solid #dedede;
    padding-bottom: 5em;

    &.module {
        width: 100vw;
        margin-top: 2em;
        margin-left: calc(-50vw + 50% - 8px);
        margin-bottom: 2em;
        margin-right: calc(-50vw + 50% - 8px);
        border-top: 1px solid #dedede;
        padding-bottom: 5em;

        .row {
            margin-bottom: 0;
        }
    }

    &.post {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .heading-special {
        margin-bottom: 1em;
    }

    .newsfeed-list {
        .newsfeed-item {
            .news-box {
                background-color: #fff;
                position: relative;
                box-shadow: 1px 1px 6px 0 rgba(107, 107, 107, .1);
                width: 100%; height: 100%;

                .entry-thumb {
                    height: 200px;
                    a {
                        display: block;                        
                    }
                    img {
                        width: 100%;
                        max-height: 200px;
                    }
                }

                .entry-meta {
                    padding: 10px 25px 35px 25px;
                }

                .entry-date {
                    text-align: left;
                    a {
                        font-size: 12px;
                        &:hover {
                            text-decoration: none;
                            span {
                                text-decoration: underline;
                            }
                        }
                        span {
                            display: inline-block;
                            margin-left: 5px;
                            &:first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .entry-title {
                    font-weight: 400px;
                    font-style: normal;
                    font-size: 18px;
                    text-transform: none;
                    line-height: 25px;
                    margin: 10px 0 0 0;

                    a {
                        color: $dark-color;
                        -o-transition: $transition-time;
                        -ms-transition: $transition-time;
                        -moz-transition: $transition-time;
                        -webkit-transition: $transition-time;
                        transition: $transition-time;

                        &:hover {
                            color: $primary-color;
                        }
                    }
                }

                .entry-content {
                    p {
                        font-size: 14px;
                        line-height: 24px;
                        margin-top: 15px;
                        color: $lighter-color;
                    }
                }

                .entry-footer {
                    position: absolute;
                    left: 0; right: 0;
                    bottom: 0;
                    padding: 0px 25px 15px 25px;
                }
            }
        }
    }

    .post {
        position: relative;
        background-color: $white-color;
        padding: 40px 0 20px 0;
        box-shadow: 1px 1px 6px 0 rgba(107, 107, 107, .1);
        margin-bottom: 40px;

        .post-head {
            position: relative;
            text-align: center;
            z-index: 0;

            .post-date {
                color: $lighter-color;
                text-transform: uppercase;
                margin-bottom: 13px;
                font-size: 14px;
            }
            .post-title {
                font-size: 2rem;
                margin-bottom: 20px;
                margin-top: 8px;
            }
        }
        .post-image {
            position: relative;
            
            img {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                height: auto;
            }
        }
        .post-body {
            margin-top: 40px;
            padding: 0 60px;

            .container {
                padding-left: 0;
                padding-right: 0;
                .row {
                    margin-bottom: 0;                    
                }
            }
            p {
                line-height: 2rem;
                margin-bottom: 20px;
            }
        }
        .post-nav {
            padding: 20px 60px;
            overflow: hidden;
            
            .post-nav-prev {
                float: left;

                .button {
                    padding: 8px 20px 10px 13px;
                }
                .svg-inline--fa {
                    margin-right: 10px;
                }
            }
            .post-nav-next {
                float: right;

                .button {
                    padding: 8px 13px 10px 20px;
                }
                .svg-inline--fa {
                    margin-left: 10px;
                }
            }
            .button {
                display: inline-block;
                border: 1px solid $primary-color;
                color: $lighter-color;
                line-height: normal;
                @include transition;

                &:hover {
                    text-decoration: none;
                    background-color: $primary-color;
                    color: $white-color;
                }
            }
        }
    }

    .widget {
        background-color: $white-color;
        padding: 30px 35px;
        box-shadow: 1px 1px 6px 0 rgba(107, 107, 107, .1);

        .post-small {
            padding: 15px 0;
        }

        .post-small-image {
            position: relative;
            margin-bottom: 20px;
            -o-transition: $transition-time;
            -ms-transition: $transition-time;
            -moz-transition: $transition-time;
            -webkit-transition: $transition-time;
            transition: $transition-time;

            img {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                height: auto;
            }

            &:hover {
                opacity: 0.7;
            }
        }
        
        .post-small-title {
            font-size: 18px;
            margin-top: 0.2rem;
            margin-bottom: 0.5rem;
        }
        .post-small-entry {
            p {
                font-size: 14px;
                margin-bottom: 0;
                line-height: 1.5rem;
            }
        }
    }
}

@media (max-width: 576px) {
    .newsfeed {
        .post {
            .post-body {
                padding: 0 35px;
                .row [class*=col-] {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }
            .post-nav {
                padding: 20px 35px;
            }
        }
    }
}

@media (max-width: 768px) {
    .newsfeed.module {
        margin: 2em calc(-50vw + 50%);
    }
}
