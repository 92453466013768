.sponsor {
    border: 2px solid #DCE0E4;
    width: 100%;
    margin: 15px 0;
    display: block;
    text-align: center;

    .lazyload {
        max-width: 100%;
    }
    img {
        max-height: 66px;
        -webkit-filter: grayscale(100%);
	    filter: grayscale(100%);

        &:hover {
            filter: grayscale(0);
        }
    }    
}