$primary-color: #AB967C;
$dark-color: #333333;
$lighter-color: rgba($color: $dark-color, $alpha: 0.9);
$white-color: #ffffff;

$header-font: 'Alegreya', serif;
$text-font: 'Lato', sans-serif;

$transition-time: 0.3s;

@mixin n-columns($min-width, $gutter, $last-equal: false, $max-cols: 5) {
    .newsfeed-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$gutter;
        margin-top: -$gutter;
    
        .newsfeed-item {
            flex: 1 0 $min-width;
            margin-left: $gutter;
            margin-top: $gutter;
    
            @if $last-equal {
                @for $i from 2 through $max-cols {
                    $screen-width: ($min-width*$i)+($gutter*$i);
                    $column-width: (100%/$i);
                    @media (min-width: $screen-width) {
                        max-width: calc(#{$column-width} - #{$gutter});
                    }
                }
        
                $column-width: (100%/$max-cols);
                @media (min-width: $min-width*$max-cols) {
                    min-width: calc(#{$column-width} - #{$gutter});
                }
            }
        }
    }
}

@mixin transition {
    -o-transition: $transition-time;
    -ms-transition: $transition-time;
    -moz-transition: $transition-time;
    -webkit-transition: $transition-time;
    transition: $transition-time;
}
