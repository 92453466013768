.event {
    color: $white-color;

    .event-date {
        flex-shrink: 0;
        background-color: $dark-color;
        width: 250px;

        h4 {
            font-size: 2.5rem;            
        }
    }
    .event-body {
        flex-grow: 1;
        background-color: $primary-color;

        span {
            display: inline-block;
            padding: 0.1rem 1.4rem;
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
            background-color: $white-color;
            color: $primary-color;            
        }
        p {
            margin-bottom: 0;
        }
    }

}
@media (max-width: 767px) {
    .event {
        .event-date {
            width: 100%;
        }
    }
}
@media (max-width: 576px) {
    .event {
        .event-date {
            width: 100%;
        }
    }
}
