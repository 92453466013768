#members-form {
    width: 100vw;
    background-color: #eaeaea;
    padding: 5em 0;
    margin-left: calc(-50vw + 50% - 8px);
    margin-right: calc(-50vw + 50% - 8px);

    .alert {
        border-radius: 0;
    }

    form {
        background-color: $white-color;
        box-shadow: 1px 1px 6px 0 rgba(107, 107, 107, .1);

        div.required label.control-label:after {
            content: " *";
            color: #dc3545;
        }

        .form-control {
            border-radius: 0;
        }

        .row {
            padding: 2em;
            border-bottom: 1px solid rgba(107, 107, 107, .1);
            margin-bottom: 0;

            .form-group:last-child {
                margin-bottom: 0;
            }
        }

        span {
            display: inline-block;
            font-weight: 700;
            margin-bottom: 1rem;
        }
        
        .button {
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: $white-color;
            padding: 0.5em 3em;
            cursor: pointer;
        }

        .has-error {
            .form-control {
                border-color: #dc3545;
            }
            .help-block {
                width: 100%;
                color: #dc3545;
                font-size: 80%;
                margin-top: 0.25rem;
            }

            .list-group-item {
                border-color: #dc3545;
                .help-block {
                    margin-top: 0; 
                    margin-bottom: 0;
                    padding: 0.5rem 1.25rem;
                    border-bottom: 1px solid #dc3545;
                }
            }
        }

        .list-group-item {
            cursor: pointer;
            padding: 0;

            &:first-child, &:last-child {
                border-radius: 0;
            }

            .list-group-content {
                padding: .75rem 1.25rem;
            }
            > input {
                visibility: hidden;
                position: absolute;
                left: 0; top: 0;

                &:checked + .list-group-content {
                    background: $primary-color;
                    color: $white-color;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    #members-form {
        form .row {
            padding: 2em 0;

            .button {
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    #members-form {
        margin: 2em calc(-50vw + 50%);
    }
}
