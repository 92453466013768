.contact-wrapper {
    .row {
        position: relative;
    }
    
    .bg-image img {
        position: absolute;
        top: 0; left: 0;
        opacity: 0.4;
        height: 100%;
    }
}

footer {
    width: 100%;
    background-color: rgba($color: $dark-color, $alpha: 0.9);
    color: $white-color;

    .subscription {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        input {
            border: 1px solid $white-color;
            border-radius: 0;
            margin-right: 1.5rem;
            flex-grow: 2;
            padding: 0.5rem 1rem;
        }
        button {
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: $white-color;
            padding: 0.5rem 3rem;
            cursor: pointer;
        }
    }

    .sub-footer {
        width: 100%;
        background-color: $dark-color;
        text-align: right;
        padding: 0.8rem 0;

        .btn-primary {
            background-color: transparent;
            border: none;
            font-size: 1rem;
            padding: 0;
            &.active {
                background-color: transparent !important;
                border: none;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-block;
            li {
                display: inline-block;
                margin-left: 0.8rem;
                &:first-child {
                    margin-left:0;
                }
                a {
                    color: $white-color;
                }
            }
        }
    }
}
