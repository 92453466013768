#map {
    width: 100%;
    height: 400px;
    background-color: grey;
}

#contact-wrapper {
    width: 100%;
    height: auto;
    background-color: #eaeaea;
    border-bottom: 1px solid #dedede;
    padding: 5em 0;

    .contact-form {
        background-color: #eaeaea;
    }

    .form-group {
        .form-control {
            border: none;
            border-radius: 0;
            outline-color: $primary-color;
            box-shadow: 1px 1px 6px 0 rgba(107, 107, 107, .1);
        }
    }

    .button {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: $white-color;
        padding: 0.5em 3em;
        cursor: pointer;
    }
}
