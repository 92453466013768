.album {
    div[class*='col-'] {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .album-item {
        color: $white-color;
        text-align: left;
        position: relative;
        width: 100%;
        height: 200px;
        background: $dark-color;

        .album-item-overlay {
            position: absolute;
            bottom: 0;
            left: 0; right: 0;
            padding: 1.25rem;

            .text {
                font-size: 0.85rem;
                margin-bottom: 0;
                line-height: 1rem;
            }
        }

        .album-item-img {
            height: auto;
            width: 100%;
        }
    }
}