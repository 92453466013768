.card {
    text-align: center;
    border: none;
    border-radius: 0;

    .card-img-top {
        height: 250px;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .card-footer {
        background-color: $white-color;
        border-top: none;
        padding-top: 0;
    }
}
